import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import SoftDrinks from "./softDrinks";
import HotDrinks from "./hotDrinks";
import Beer from "./beer";
import Spirits from "./spirits";
import Liquors from "./liquors";
import RedWine from "./redWine";
import WhiteWine from "./whiteWine";
import Sparkling from "./sparkling";
import LebaneseWine from "./lebaneseWine";

const Drinks = () => {
  const [alignment, setAlignment] = React.useState("left");
  const [showDrinks, setShowDrinks] = React.useState(true);
  const [showHotDrinks, setShowHotDrinks] = React.useState(false);
  const [showAlcoholicBeverages, setShowAlcoholicBeverages] =
    React.useState(false);
  const [showBeer, setShowBeer] = React.useState(false);
  const [showSpirits, setShowSpirits] = React.useState(false);
  const [showLiqour, setShowLiqour] = React.useState(false);
  const [showRedWine, setShowRedWine] = React.useState(false);
  const [showWhiteWine, setShowWhiteWine] = React.useState(false);
  const [showSparkling, setShowSparkling] = React.useState(false);
  const [showLebaneseWine, setShowLebaneseWine] = React.useState(false);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);

    if (newAlignment === "drinks") {
      setShowDrinks(true);
      setShowHotDrinks(false);
      setShowAlcoholicBeverages(false);
    } else if (newAlignment === "hotDrinks") {
      setShowHotDrinks(true);
      setShowDrinks(false);
      setShowAlcoholicBeverages(false);
    } else if (newAlignment === "alcoholicBeverages") {
      setShowAlcoholicBeverages(true);
      setShowBeer(true);
      setShowDrinks(false);
      setShowHotDrinks(false);
    } else if (newAlignment === "backToDrinks") {
      setShowAlcoholicBeverages(false);
    } else if (newAlignment === "beer") {
      setShowBeer(true);
      setShowSpirits(false);
      setShowLiqour(false);
      setShowRedWine(false);
      setShowWhiteWine(false);
      setShowSparkling(false);
      setShowLebaneseWine(false);
    } else if (newAlignment === "spirits") {
      setShowSpirits(true);
      setShowBeer(false);
      setShowLiqour(false);
      setShowRedWine(false);
      setShowWhiteWine(false);
      setShowSparkling(false);
      setShowLebaneseWine(false);
    } else if (newAlignment === "liquorsaperitifs") {
      setShowLiqour(true);
      setShowBeer(false);
      setShowSpirits(false);
      setShowRedWine(false);
      setShowWhiteWine(false);
      setShowSparkling(false);
      setShowLebaneseWine(false);
    } else if (newAlignment === "redwine") {
      setShowRedWine(true);
      setShowBeer(false);
      setShowSpirits(false);
      setShowLiqour(false);
      setShowWhiteWine(false);
      setShowSparkling(false);
      setShowLebaneseWine(false);
    } else if (newAlignment === "whitewine") {
      setShowWhiteWine(true);
      setShowBeer(false);
      setShowSpirits(false);
      setShowLiqour(false);
      setShowRedWine(false);
      setShowSparkling(false);
      setShowLebaneseWine(false);
    } else if (newAlignment === "lebanesewine") {
      setShowWhiteWine(false);
      setShowBeer(false);
      setShowSpirits(false);
      setShowLiqour(false);
      setShowRedWine(false);
      setShowSparkling(false);
      setShowLebaneseWine(true);
    } else if (newAlignment === "sparkling") {
      setShowWhiteWine(false);
      setShowBeer(false);
      setShowSpirits(false);
      setShowLiqour(false);
      setShowRedWine(false);
      setShowSparkling(true);
      setShowLebaneseWine(false);
    }
  };

  const children = [
    <ToggleButton
      value="drinks"
      key="drinks"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Drinks
    </ToggleButton>,
    <ToggleButton
      value="hotDrinks"
      key="hotDrinks"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Hot Drinks
    </ToggleButton>,
    <ToggleButton
      value="alcoholicBeverages"
      key="alcoholicBeverages"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Alcoholic Beverages
    </ToggleButton>,
  ];

  const alcoholicBeverages = [
    <ToggleButton
      value="backToDrinks"
      key="backToDrinks"
      sx={{ fontSize: "1em" }}
    >
      Back to Drinks
    </ToggleButton>,
    <ToggleButton
      value="beer"
      key="beer"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Beer /Cider
    </ToggleButton>,
    <ToggleButton
      value="spirits"
      key="spirits"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Spirits
    </ToggleButton>,
    <ToggleButton
      value="liquorsaperitifs"
      key="liquorsaperitifs"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Liquors & Aperitifs
    </ToggleButton>,
    <ToggleButton
      value="redwine"
      key="redwine"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Red Wine
    </ToggleButton>,
    <ToggleButton
      value="whitewine"
      key="whitewine"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      White Wine
    </ToggleButton>,
    <ToggleButton
      value="lebanesewine"
      key="lebanesewine"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Lebanese Wine
    </ToggleButton>,
    <ToggleButton
      value="sparkling"
      key="sparkling"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Sparkling
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };
  return (
    <Box>
      <Box
        mt={5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        {!showAlcoholicBeverages ? (
          <Typography variant="h3">Drinks</Typography>
        ) : (
          <Typography variant="h3">Alcoholic Beverages</Typography>
        )}
        <Box>
          <Stack spacing={2} alignItems="center">
            {!showAlcoholicBeverages ? (
              <ToggleButtonGroup
                size="small"
                {...control}
                aria-label="Medium sizes"
                sx={{ flexWrap: "wrap", justifyContent: "center" }}
              >
                {children}
              </ToggleButtonGroup>
            ) : (
              <ToggleButtonGroup
                size="small"
                {...control}
                aria-label="Medium sizes"
                sx={{ flexWrap: "wrap", justifyContent: "center" }}
              >
                {alcoholicBeverages}
              </ToggleButtonGroup>
            )}
          </Stack>
        </Box>
      </Box>
      {showDrinks && <SoftDrinks />}
      {showHotDrinks && <HotDrinks />}
      {showBeer && <Beer />}
      {showSpirits && <Spirits />}
      {showLiqour && <Liquors />}
      {showRedWine && <RedWine />}
      {showWhiteWine && <WhiteWine />}
      {showSparkling && <Sparkling />}
      {showLebaneseWine && <LebaneseWine />}
    </Box>
  );
};

export default Drinks;
