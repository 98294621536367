import { Box, Button, Divider, Menu, MenuItem } from "@mui/material";
import img from "./assets/rosese logo.jpeg";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { Link } from "react-router-dom";

const SmallNav = () => {
	const [more, setMore] = React.useState<null | HTMLElement>(null);
	const openMore = Boolean(more);
	const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMore(event.currentTarget);
	};
	const handleMoreClose = () => {
		setMore(null);
	};
	return (
		<Box>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Button href="/">
					<img
						src={img}
						alt="logo"
						style={{ width: "230px", height: "auto", paddingLeft: "0px" }}
					/>
				</Button>
				<Box display="flex">
					<Button
						variant="contained"
						sx={{ mx: 3, width: "80px" }}
						id="basic-button"
						aria-controls={openMore ? "more-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={openMore ? "true" : undefined}
						onClick={handleMoreClick}
					>
						<MenuIcon sx={{ fontSize: 30 }} />
					</Button>
					<Menu
						id="more-menu"
						anchorEl={more}
						open={openMore}
						onClose={handleMoreClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						<MenuItem component={Link} to="/" onClick={handleMoreClose}>
							Home
						</MenuItem>
						<MenuItem component={Link} to="/about" onClick={handleMoreClose}>
							About Us
						</MenuItem>
						<MenuItem component={Link} to="/menus" onClick={handleMoreClose}>
							Menus
						</MenuItem>
						<MenuItem component={Link} to="/contact" onClick={handleMoreClose}>
							Contact Us
						</MenuItem>
						<Divider />
						<MenuItem component={Link} to="/catering" onClick={handleMoreClose}>
							Catering
						</MenuItem>
						<MenuItem
							component={Link}
							to="/functions"
							onClick={handleMoreClose}
						>
							Functions
						</MenuItem>
						<Divider />
						<MenuItem
							component={Link}
							to="/fundraising"
							onClick={handleMoreClose}
						>
							Fund Raising
						</MenuItem>
						<MenuItem
							component={Link}
							to="/giftvouchers"
							onClick={handleMoreClose}
						>
							Gift Vouchers
						</MenuItem>
						<MenuItem component={Link} to="/gallery" onClick={handleMoreClose}>
							Gallery
						</MenuItem>
						<MenuItem
							component={Link}
							to="/facilities"
							onClick={handleMoreClose}
						>
							Facilities
						</MenuItem>
					</Menu>
				</Box>
			</Box>
		</Box>
	);
};

export default SmallNav;
