import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import LightMeals from "./LightMeals";
import LebanesePlates from "./LebanesePlates";
import Seafood from "./Seafood";
import MeatDishes from "./MeatDishes";
import ContinentalMeals from "./ContinentalMeals";
import Desserts from "./Desserts";
import Sides from "./Sides";

const TakeAway = () => {
  const [alignment, setAlignment] = React.useState("left");
  const [showLebanesePlates, setShowLebanesePlates] = React.useState(false);
  const [showSeafood, setShowSeafood] = React.useState(false);
  const [showLightMeals, setShowLightMeals] = React.useState(true);
  const [showMeatDishes, setShowMeatDishes] = React.useState(false);
  const [showContinentalMeals, setShowContinentalMeals] = React.useState(false);
  const [showDesserts, setShowDesserts] = React.useState(false);
  const [showMisc, setShowMisc] = React.useState(false);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);

    if (newAlignment === "lightmeals") {
      setShowLightMeals(true);
      setShowLebanesePlates(false);
      setShowSeafood(false);
      setShowMeatDishes(false);
      setShowContinentalMeals(false);
      setShowDesserts(false);
      setShowMisc(false);
    } else if (newAlignment === "lebaneseplates") {
      setShowLebanesePlates(true);
      setShowLightMeals(false);
      setShowSeafood(false);
      setShowMeatDishes(false);
      setShowContinentalMeals(false);
      setShowDesserts(false);
      setShowMisc(false);
    } else if (newAlignment === "seafood") {
      setShowSeafood(true);
      setShowLightMeals(false);
      setShowLebanesePlates(false);
      setShowMeatDishes(false);
      setShowContinentalMeals(false);
      setShowDesserts(false);
      setShowMisc(false);
    } else if (newAlignment === "meatdishes") {
      setShowMeatDishes(true);
      setShowLightMeals(false);
      setShowLebanesePlates(false);
      setShowSeafood(false);
      setShowContinentalMeals(false);
      setShowDesserts(false);
      setShowMisc(false);
    } else if (newAlignment === "continentalmeals") {
      setShowContinentalMeals(true);
      setShowLightMeals(false);
      setShowLebanesePlates(false);
      setShowSeafood(false);
      setShowMeatDishes(false);
      setShowDesserts(false);
      setShowMisc(false);
    } else if (newAlignment === "desserts") {
      setShowDesserts(true);
      setShowLightMeals(false);
      setShowLebanesePlates(false);
      setShowSeafood(false);
      setShowMeatDishes(false);
      setShowContinentalMeals(false);
      setShowMisc(false);
    } else if (newAlignment === "misc") {
      setShowMisc(true);
      setShowLightMeals(false);
      setShowLebanesePlates(false);
      setShowSeafood(false);
      setShowMeatDishes(false);
      setShowContinentalMeals(false);
      setShowDesserts(false);
    }
  };

  const children = [
    <ToggleButton
      value="lightmeals"
      key="lightmeals"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Light Meals
    </ToggleButton>,
    <ToggleButton
      value="lebaneseplates"
      key="lebaneseplates"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Lebanese Plates
    </ToggleButton>,
    <ToggleButton
      value="seafood"
      key="seafood"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Seafood
    </ToggleButton>,
    <ToggleButton
      value="meatdishes"
      key="meatdishes"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Meat Dishes
    </ToggleButton>,
    <ToggleButton
      value="continentalmeals"
      key="continentalmeals"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Continental Meals
    </ToggleButton>,
    <ToggleButton
      value="desserts"
      key="desserts"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Desserts
    </ToggleButton>,
    <ToggleButton
      value="misc"
      key="misc"
      sx={{ color: "#9b3f3f", fontSize: "1em" }}
    >
      Sides
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };
  return (
    <Box>
      <Box
        mt={5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Typography variant="h3">Take Away</Typography>
        <Box>
          <Stack spacing={2} alignItems="center">
            <ToggleButtonGroup
              size="small"
              {...control}
              aria-label="Medium sizes"
              sx={{ flexWrap: "wrap", justifyContent: "center" }}
            >
              {children}
            </ToggleButtonGroup>
          </Stack>
        </Box>
      </Box>
      {showLightMeals && <LightMeals />}
      {showLebanesePlates && <LebanesePlates />}
      {showSeafood && <Seafood />}
      {showMeatDishes && <MeatDishes />}
      {showContinentalMeals && <ContinentalMeals />}
      {showDesserts && <Desserts />}
      {showMisc && <Sides />}
    </Box>
  );
};

export default TakeAway;
