const TakeAway = [
  {
    name: "Lebanese Plates",
    description: "",
    items: [
      {
        name: "Vegetarian",
        price: "28",
        description: "Homous, tabouli, baba ganouje, 3 falafels, beans, vine leaf and an omelette"
      },
      {
        name: "Mixed Plate",
        price: "30",
        description: "Homous, tabouli, beans, cabbage roll, rice, an omelette, 1 chicken skewer and 1 lamb skewer"
      },
      {
        name: "Chicken Plate",
        price: "30",
        description: "Homous, tabouli, beans, cabbage roll, rice, an omelette and 2 chicken skewers"
      },
      {
        name: "Lamb Plate",
        price: "30",
        description: "Homous, tabouli, beans, cabbage roll, rice, an omelette and 2 lamb skewers"
      },
      {
        name: "Kafta Plate",
        price: "30",
        description: "Homous, tabouli, beans, cabbage roll, rice, an omelette and 2 kafta skewers"
      }
    ]
  },
  {
    name: "Seafood",
    description: "",
    items: [
      {
        name: "Samke Harrah",
        price: "30",
        description: "Fresh ling fish fillets marinated in chilli, tahini, garlic and spices, sprinkled with almonds and pine nuts, served with rice and fatoush"
      },
      {
        name: "Iraydis",
        price: "30",
        description: "Prawns marinated in garlic and lemon juice, served on a bed of rice with fatoush salad and homous"
      },
      {
        name: "Local Smoked Trout",
        price: "32",
        description: "Whole smoked trout served with baba ganouje, rice and fatoush salad"
      }
    ]
  },
  {
    name: "Meat Dishes",
    description: "",
    items: [
      {
        name: "Shawarma",
        price: "28",
        description: "Slices of shaved marinated beef topped with tahini and onions, served with fatoush salad, beans, homous and rice"
      },
      {
        name: "Malfouf",
        price: "28",
        description: "A serving of cabbage rolls with minced meat, rice, mint, tomato, onions and spices, with fatoush salad, beans and homous"
      },
      {
        name: "M'orniek",
        price: "28",
        description: "Spiced Lebanese beef sausages sprinkled with lemon juice, served with fatoush salad, beans, homous and rice"
      },
      {
        name: "Kebbe Miklieyeh",
        price: "28",
        description: "Crushed wheat and minced lamb stuffed with lamb, pine nuts, onions and herbs, served with fatoush salad, beans, homous and labne."
      },
      {
        name: "Kafta",
        price: "28",
        description: "Spiced minced lamb, parsley and onions served with rice, fatoush salad, beans and homous"
      },
      {
        name: "Lahum Mishwa",
        price: "28",
        description: "Finest lamb pieces marinated in spices, vinegar, onion and olive oil, served with fatoush salad, beans, homous and rice"
      }
    ]
  },
    {
    name: "Continental Meals",
    description: "",
    items: [
      {
        name: "Homemade Spaghetti Bolognaise",
        price: "24",
        description: ""
      },
      {
        name: "Chicken or Veal Schnitzel",
        price: "26",
        description: "Served with salad and chips"
      },
      {
        name: "500g Local Cut Rump Steak",
        price: "40",
        description: "Served with salad, chips, and your choice of sauce"
      },
      {
        name: "300g Local Cut Sirlion Steak",
        price: "38",
        description: "Served with salad, chips, and your choice of sauce"
      },
      {
        name: "Garlic Prawns",
        price: "30",
        description: "Served on a bed of rice with salad"
      },
      {
        name: "Fisherman's Catch",
        price: "26",
        description: "Battered fish, prawns, scallops, calamari, and crab meat served with a wedge of lemon, salad and chips"
      }
    ]
  },
  {
    name: "Desserts",
    description: "",
    items: [
      {
        name: "Baclawa",
        price: "4.00 each",
        description: "",
      },
      {
        name: "Turkish delight",
        price: "0.90 each",
        description: "",
      },
      {
        name: "Mini Baclawa",
        price: "1.50 each",
        description: "",
      },
      {
        name: "Shortbread Biscuits",
        price: "1.50 each",
        description: "",
      },
      {
        name: "Drunken Soldier",
        price: "9.50",
        description: ""
      },
      {
        name: "Tiramisu",
        price: "9.50",
        description: ""
      },
      {
        name: "Kineffe",
        price: "9.50",
        description: ""
      }
    ]
  },
]


export default TakeAway;