import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import bottle1 from "../../assets/bottle1.png";
import bottle2 from "../../assets/bottle2.png";
import bottle3 from "../../assets/bottle3.png";

const LebaneseWine = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box
        mb={4}
        sx={{
          mx: isSmallScreen ? 3 : 8,
          width: isSmallScreen ? "80vw" : "65vw",
        }}
      >
        <Grid container mt={6}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              Lebanese Wine
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={10}>
            <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
              Ksara Reserve du Couvent
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              $38.00 Bottle
            </Typography>
          </Grid>
          <Grid
            item
            mt={2}
            xs={12}
            md={11}
            px={2}
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
              From its founding in 1857, Chateau Ksara Estate in the Bekaa
              Valley is Lebanon's oldest winery. The Reserve Du Couvent is the
              backbone of the Chateau Ksara range, a ready to drink, medium to
              full bodies blend of Syrah, Cabernet Franc, and Cabernet Sauvignon
              with layered aromas of wood and vanilla. Supple, pure, and round
              tannins give it length and persistence on the palate.
            </Typography>
            <img
              src={bottle1}
              alt="bottle of wine"
              style={{ height: "200px", width: "auto" }}
            />
          </Grid>
        </Grid>
        <Grid container mt={8}>
          <Grid item xs={10}>
            <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
              Ksara Château Rouge
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              $72.00 Bottle
            </Typography>
          </Grid>
          <Grid
            item
            mt={2}
            xs={12}
            md={11}
            px={2}
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
              Named after the winery, this is one of the best from the Chateau
              Ksara Estate. A full-bodied blend of Cabernet Sauvignon, Merlot,
              and Petit Verdot. Starting with aromas of raspberries,
              blackcurrant, and hints of vanilla, then leading to flavours of
              red and black fruit, cedar and bell peppers with mellow tannins
              that develop with time, taking up a leather and spice character.
            </Typography>
            <img
              src={bottle2}
              alt="bottle of wine"
              style={{ height: "200px", width: "auto" }}
            />
          </Grid>
        </Grid>
        <Grid container mt={8}>
          <Grid item xs={10}>
            <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
              Fakra Collection Privee
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              $95.00 Bottle
            </Typography>
          </Grid>
          <Grid
            item
            mt={2}
            xs={12}
            md={11}
            mb={1}
            px={2}
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
              Named after the winery, this is one of the best from the Chateau
              Ksara Estate. A full-bodied blend of Cabernet Sauvignon, Merlot,
              and Petit Verdot. Starting with aromas of raspberries,
              blackcurrant, and hints of vanilla, then leading to flavours of
              red and black fruit, cedar and bell peppers with mellow tannins
              that develop with time, taking up a leather and spice character.
            </Typography>
            <img
              src={bottle3}
              alt="bottle of wine"
              style={{ height: "200px", width: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LebaneseWine;
