const Wine = [
        {
        name: "Red Wine",
        items: [
            {
                name: "House Red",
                glass: "8.00",
                bottle: "-",
                description: "Cabernet Sauvignon"
            },
            {
                name: "House Red",
                glass: "8.00",
                bottle: "-",
                description: "Merlot"
            },
            {
                name: "House Red",
                glass: "8.50",
                bottle: "-",
                description: "Shiraz"
            },
            {
                name: "Days of Rosé",
                glass: "8.00",
                bottle: "28.00",
                description: "Rosé, Reynella, SA"
            },
            {
                name: "19 Crimes",
                glass: "9.50",
                bottle: "30.00",
                description: "Pinot Noir, Nurioopta, SA"
            },
            {
                name: "Rawson's Retreat",
                glass: "-",
                bottle: "25.00",
                description: "Cabernet Sauvignon, Nuriootpa, SA"
            },
            {
                name: "Brown Brothers",
                glass: "-",
                bottle: "28.00",
                description: "Dolcetto & Syrah (sweet), Milawa, Victoria"
            },
            {
                name: "Farm Hand",
                glass: "-",
                bottle: "30.00",
                description: "Merlot (organic), Monash Valley, SA"
            },
            {
                name: "Taylor's",
                glass: "-",
                bottle: "30.00",
                description: "Shiraz, Clare Valley, SA"
            },
            {
                name: "Shut the Gate",
                glass: "-",
                bottle: "30.00",
                description: "Shiraz, Hilltops, NSW"
            },
            {
                name: "Bunnamagoo Estate",
                glass: "-",
                bottle: "32.00",
                description: "Shiraz, Mudgee, NSW"
            },
            {
                name: "Robert Oatley",
                glass: "-",
                bottle: "35.00",
                description: "Shiraz, Mudgee, NSW"
            },
            {
                name: "Bunnamagoo Estate",
                glass: "-",
                bottle: "40.00",
                description: "Cabernet Sauvignon, Merlot, Shiraz, Mudgee, NSW"
            },
            {
                name: "Robert Oatley",
                glass: "-",
                bottle: "40.00",
                description: "Pinot Noir, Mudgee, NSW"
            },
            {
                name: "Penfold's BIN 389",
                glass: "-",
                bottle: "130.00",
                description: "Cabernet Shiraz, SA"
            }
        ]
    },
    {
        name: "White Wine",
        items: [
            {
                name: "Brown Brothers (sweet)",
                glass: "8.00",
                bottle: "22.00",
                description: "Crouchen Riesling Milawa, Victoria"
            },
            {
                name: "Farm Hand (Organic)",
                glass: "8.50",
                bottle: "24.00",
                description: "Chardonnay Nuriootpa, SA"
            },
            {
                name: "Farm Hand (Organic)",
                glass: "8.50",
                bottle: "24.00",
                description: "Semillon Sauvignon Blanc Monash Valley, SA"
            },
            {
                name: "Wild Oats",
                glass: "9.00",
                bottle: "30.00",
                description: "Pinot Grigio Mudgee, NSW"
            },
            {
                name: "Taylors",
                glass: "9.00",
                bottle: "32.00",
                description: "Sauvignon Blanc Adelaide Hills, SA"
            },
            {
                name: "Brown Brothers",
                glass: "-",
                bottle: "28.00",
                description: "Moscato Milawa, VIC"
            },
            {
                name: "Bunnamagoo Estate",
                glass: "-",
                bottle: "28.00",
                description: "Riesling Mudgee, NSW"
            },
            {
                name: "Oyster Bay",
                glass: "-",
                bottle: "30.00",
                description: "Sauvignon Blanc Marlborough, New Zealand"
            },
            {
                name: "Shut the Gate",
                glass: "-",
                bottle: "30.00",
                description: "Pinot Gris Hilltops, NSW"
            }
        ]
    }
]

export default Wine; 