import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import Roses from "./assets/Roses.jpeg";
import Nav from "./nav";
import Footer from "./footer";
import PhoneIcon from "./assets/PhoneIcon.svg";
import EmailIcon from "./assets/EmailIcon.svg";
import locationIcon from "./assets/LocationIcon.svg";

const Contact = () => {
  const changeScreen = useMediaQuery("(max-width: 715px)");
  return (
    <Box>
      <Nav />
      <Grid container mt={6} mb={6}>
        <Grid
          item
          xs={12}
          md={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h1">Contact Us</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              backgroundImage: `url(${Roses})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              minHeight: "800px",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="90%"
              pt={10}
              pl={5}
            >
              <Box display="flex" alignItems="center" mb={3}>
                <Button href="tel:+0264524512" target="_blank">
                  <img src={PhoneIcon} alt="Phone Icon" width="60px" />
                </Button>
                <Typography
                  variant="h4"
                  sx={{ fontSize: changeScreen ? "1.5em" : "2em", ml: 2 }}
                >
                  <a
                    style={{
                      color: "#3c5434",
                    }}
                    href="tel:0264524512"
                  >
                    {" "}
                    (02) 6452 45 12{" "}
                  </a>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={3}>
                <Button
                  href="mailto:contact@rosesrestaurant.com.au"
                  target="_blank"
                >
                  <img src={EmailIcon} alt="Email Icon" width="60px" />
                </Button>
                <Typography
                  variant="h4"
                  sx={{ fontSize: changeScreen ? "1.5em" : "2em", ml: 2 }}
                >
                  <a
                    href="mailto:contact@rosesrestaurant.com.au"
                    style={{ color: "#3c5434" }}
                  >
                    {" "}
                    contact @ roses restaurant .com .au{" "}
                  </a>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={3}>
                <Button
                  href="https://www.google.com/maps/search/?api=1&query=69+Massie+St+Cooma+NSW+2630"
                  target="_blank"
                >
                  <img src={locationIcon} alt="Location Icon" width="60px" />
                </Button>
                <Typography
                  variant="h4"
                  sx={{ fontSize: changeScreen ? "1.5em" : "2em", ml: 2 }}
                >
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=69+Massie+St+Cooma+NSW+2630"
                    style={{ color: "#3c5434" }}
                  >
                    69 Massie St Cooma NSW 2630{" "}
                  </a>
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={4}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3218.2337512688487!2d149.12473889999998!3d-36.233815299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b3da81505bd0ba1%3A0x41a2b0a259f18ab0!2s69%20Massie%20St%2C%20Cooma%20NSW%202630!5e0!3m2!1sen!2sau!4v1688001728173!5m2!1sen!2sau"
                  height="300"
                  style={{ border: 0, width: changeScreen ? "300px" : "600px" }}
                  loading="lazy"
                  title="contact"
                ></iframe>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default Contact;
