import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DessertMenuData from "../../assets/data/desserts";

const Desserts = () => {
	const isSmallScreen = useMediaQuery("(max-width: 900px)");

	return (
		<Box>
			<Box mt={5} display="flex" flexDirection="column" justifyContent="center">
				<Typography variant="h3" sx={{ textAlign: "center" }}>
					Desserts
				</Typography>
				<Box my={3} sx={{ mx: isSmallScreen ? 3 : 8 }}>
					{DessertMenuData.map((item, index) => (
						<Grid container mt={4}>
							<Grid item xs={10}>
								<Typography
									sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}
								>
									{item.name}
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
									${item.price}
								</Typography>
							</Grid>
							<Grid item xs={9} px={2}>
								<Typography
									sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}
								>
									{item.description}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default Desserts;
