const Drinks = [
    {
        name: "Drinks",
        items: [
            {
                name: "Soft drinks",
                price: 3.9
            },
            {
                name: "Jug of soft drink", 
                price: 9.5
            },
            {
                name: "Jug of Lemon Lime Bitters",
                price: 12.5
            },
            {
                name: "Fire Engine",
                price: 3.9
            },
            {
                name: "Ginger beer",
                price: 3.9
            },
            {
                name: "Juice",
                price: "4.0"
            },
            {
                name: "Milkshake",
                price: 6.5
            },
            {
                name: "Lemon, Lime & Bitters",
                price: 4.5
            },
            {
                name: "Sparking Apple Juice",
                price: 4.5
            },
            {
                name: "Sparkling Grape Juice",
                price: 4.5
            },
            {
                name: "Beloka 750ml Mineral water",
                price: 9.5
            },
        ]
    },
    {
        name: "Hot Drinks",
        items: [
        {
            name: "Lebanese Coffee",
            price: 4.5
        },
        {
            name: "Pot of Tea",
            price: 4.5
        },
        {
            name: "Herbal Tea",
            price: 4.5
        },
        {
            name: "Cappuccino",
            price: 4.9
        },
        {
            name: "Flat white",
            price: 4.9
        },
        {
            name: "Muggaccino",
            price: "5.0"
        },
        {
            name: "Flat white mug",
            price: "5.0"
        },
        {
            name: "Latte",
            price: "5.0"
        },
        {
            name: "Long Black",
            price: 4.9
        },
        {
            name: "Short Black",
            price: "4.0"
        },
        {
            name: "Macchiato",
            price: 4.5
        },
        {
            name: "Mocha",
            price: "5.0"
        },
        {
            name: "Hot Chocolate",
            price: 4.5
        },
        {
            name: "Piccolo",
            price: 4.5
        },
        {
            name: "Liqueur Coffee",
            price: "10.0"
        }
        ]
    }
];

export default Drinks;