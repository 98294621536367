import {
	Box,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import React from "react";
import Kebabs from "./kebabs";
import LightMeals from "./lightMeals";
import LebanesePlates from "./lebanesePlates";
import Seafood from "./seafood";
import ContinentalMeals from "./continentalMeals";

const Lunch = () => {
	const [alignment, setAlignment] = React.useState("left");
	const [showKebabs, setShowKebabs] = React.useState(true);
	const [showLightMeals, setShowLightMeals] = React.useState(false);
	const [showLebanesePlates, setShowLebanesePlates] = React.useState(false);
	const [showSeafood, setShowSeafood] = React.useState(false);
	const [showContinentalMeals, setShowContinentalMeals] = React.useState(false);

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string,
	) => {
		setAlignment(newAlignment);

		if (newAlignment === "farLeft") {
			setShowKebabs(true);
			setShowLightMeals(false);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "centerLeft") {
			setShowKebabs(false);
			setShowLightMeals(true);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "center") {
			setShowKebabs(false);
			setShowLightMeals(false);
			setShowLebanesePlates(true);
			setShowSeafood(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "centerRight") {
			setShowKebabs(false);
			setShowLightMeals(false);
			setShowLebanesePlates(false);
			setShowSeafood(true);
			setShowContinentalMeals(false);
		} else if (newAlignment === "farRight") {
			setShowKebabs(false);
			setShowLightMeals(false);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowContinentalMeals(true);
		}
	};

	const children = [
		<ToggleButton
			value="farLeft"
			key="farLeft"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Kebabs
		</ToggleButton>,
		<ToggleButton
			value="centerLeft"
			key="centerLeft"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Light Meals
		</ToggleButton>,
		<ToggleButton
			value="center"
			key="center"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Lebanese Plates
		</ToggleButton>,
		<ToggleButton
			value="centerRight"
			key="centerRight"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Seafood
		</ToggleButton>,
		<ToggleButton
			value="farRight"
			key="farRight"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Continental Meals
		</ToggleButton>,
	];

	const control = {
		value: alignment,
		onChange: handleChange,
		exclusive: true,
	};
	return (
		<Box>
			<Box
				mt={5}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				textAlign="center"
			>
				<Typography variant="h3">Lunch</Typography>
				<Box>
					<Stack spacing={2} alignItems="center">
						<ToggleButtonGroup
							size="small"
							{...control}
							aria-label="Medium sizes"
							sx={{ flexWrap: "wrap", justifyContent: "center" }}
						>
							{children}
						</ToggleButtonGroup>
					</Stack>
				</Box>
			</Box>
			{showKebabs && <Kebabs />}
			{showLightMeals && <LightMeals />}
			{showLebanesePlates && <LebanesePlates />}
			{showSeafood && <Seafood />}
			{showContinentalMeals && <ContinentalMeals />}
		</Box>
	);
};

export default Lunch;
