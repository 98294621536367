import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Roses from "./assets/Roses.jpeg";
import Footer from "./footer";
import Nav from "./nav";

const InfoPage = (props: {
	title: string;
	para1: string;
	para2: string;
	para3: string;
	para4: string;
	img: string;
	altImg: string;
}) => {
	const isMediumScreen = useMediaQuery("(max-width:900px)");
	const isSmallScreen = useMediaQuery("(max-width:600px)");
	return (
		<Box>
			<Nav />
			<Grid container mt={2} mb={6}>
				{!isMediumScreen ? null : (
					<Grid
						item
						xs={12}
						md={4}
						my={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h1">{props.title}</Typography>
					</Grid>
				)}
				<Grid item xs={12} md={8}>
					<Box
						sx={{
							backgroundImage: `url(${Roses})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							height: "80vh",
							display: "flex",
							alignItems: "center",
							padding: "1rem",
							justifyContent: "center",
						}}
					>
						<Box width="90%" height="80%" overflow="auto" alignItems="center">
							<Box textAlign="center">
								<Typography variant="body1">{props.para1}</Typography>
								<Typography variant="body1" sx={{ mt: 3 }}>
									{props.para2}
								</Typography>
								<Typography variant="body1" sx={{ mt: 3 }}>
									{props.para3}
								</Typography>
								<Typography variant="body1" sx={{ mt: 3 }}>
									{props.para4}
								</Typography>
								<img
									style={{
										width: !isSmallScreen ? "400px" : "200px",
										height: "auto",
										marginTop: "2rem",
									}}
									src={props.img}
									alt={props.altImg}
								/>
							</Box>
						</Box>
					</Box>
				</Grid>
				{isMediumScreen ? null : (
					<Grid
						item
						pl={1}
						xs={12}
						md={4}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h1">{props.title}</Typography>
					</Grid>
				)}
			</Grid>
			<Footer />
		</Box>
	);
};

export default InfoPage;
