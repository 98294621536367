import { Box, Button, Grid, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import TripAdviserIcon from "./assets/TripAdviserIcon.svg";
import FacebookIcon from "./assets/FaceBook.svg";
import PhoneIcon from "./assets/PhoneIcon.svg";
import EmailIcon from "./assets/EmailIcon.svg";
import locationIcon from "./assets/LocationIcon.svg";

const Footer = () => {
  return (
    <Box
      sx={{ backgroundColor: "#3c5434" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Grid container spacing={5} width="90%">
        <Grid item xs={12} sm={6} lg={4} mt={6}>
          <Grid
            container
            spacing={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Grid item display="flex" alignItems="center">
              <Typography variant="h4">Services</Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <CircleIcon sx={{ color: "#8a9470", fontSize: "0.6em", mr: 1 }} />
              <Typography variant="h6">Weddings</Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <CircleIcon sx={{ color: "#8a9470", fontSize: "0.6em", mr: 1 }} />
              <Typography variant="h6">Christenings</Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <CircleIcon sx={{ color: "#8a9470", fontSize: "0.6em", mr: 1 }} />
              <Typography variant="h6">Birthdays</Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <CircleIcon sx={{ color: "#8a9470", fontSize: "0.6em", mr: 1 }} />
              <Typography variant="h6">Corporate Functions</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} mt={6}>
          <Box width="60%">
            <Typography variant="h4">Business Hours</Typography>
            <Typography variant="h6">Open 6 Days</Typography>
            <Typography variant="h6">Monday - Saturday</Typography>
            <Typography variant="h6">11:30am - 2:00pm Lunch</Typography>
            <Typography variant="h6">6pm - Late Dinner</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} mt={6} display="flex" justifyContent="center">
          <Box>
            <Typography variant="h5">
              Open on Sunday's from 6pm Only during winter school holidays
            </Typography>
            <Box mt={2} display="flex" justifyContent="center">
              <Box p={1}>
                <Button
                  href="https://www.tripadvisor.com.au/Restaurant_Review-g261610-d2442672-Reviews-Rose_s_Lebanese_Restaurant-Cooma_New_South_Wales.html"
                  target="_blank"
                >
                  <img
                    src={TripAdviserIcon}
                    alt="trip advisor Icon"
                    width="60px"
                  />
                </Button>
                <Button
                  href="https://www.facebook.com/rosesrestaurantcooma/"
                  target="_blank"
                >
                  <img src={FacebookIcon} alt="Facebook Icon" width="60px" />
                </Button>
                <Button href="tel:0264524512" target="_blank">
                  <img src={PhoneIcon} alt="Phone Icon" width="60px" />
                </Button>
                <Button
                  href="mailto:contact@rosesrestaurant.com.au"
                  target="_blank"
                >
                  <img src={EmailIcon} alt="Email Icon" width="60px" />
                </Button>
                <Button
                  href="https://www.google.com/maps/search/?api=1&query=69+Massie+St+Cooma+NSW+2630"
                  target="_blank"
                >
                  <img src={locationIcon} alt="Location Icon" width="60px" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="h5">Powered by Block Software</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
          <Typography variant="body2">
            Copyright © 2023 Roses Restaurant Online. All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
