import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

const LightMeals = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box mb={4} sx={{ mx: isSmallScreen ? 3 : 8 }}>
        <Grid container mt={6}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              Light Meals
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: isSmallScreen ? "1em" : "1.3em" }}
            ></Typography>
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={10}>
            <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
              Kebabs (wraps)
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              $13
            </Typography>
          </Grid>
          <Grid item xs={9} px={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
              Served with lettuce, tomato, onion, tabouli, homous, tahini, and
              your choice of:
            </Typography>
            <Box display="flex" mt={2}>
              <Typography
                sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em", mr: 2 }}
              >
                Beef-
              </Typography>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                Fresh marinated beef
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              <Typography
                sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em", mr: 2 }}
              >
                Chicken-
              </Typography>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                Fresh marinated chicken breast
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              <Typography
                sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em", mr: 2 }}
              >
                Lamb-
              </Typography>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                Fresh marinated lamb pieces
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              <Typography
                sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em", mr: 2 }}
              >
                Falafel-
              </Typography>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                Vegetarian
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LightMeals;
