import { Box } from "@mui/material";
import InfoPage from "./info";

const Catering = () => {
	return (
		<Box>
			<InfoPage
				title={"Catering"}
				para1={""}
				para2={""}
				para3={""}
				img={""}
				altImg={""}
				para4={
					"External catering is available for corporate events or other occasions. Rose's Restaurant staff will work with you to create the perfect selection of foods to impress your guests and make your event unforgettable."
				}
			/>
		</Box>
	);
};

export default Catering;
