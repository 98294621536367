import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import Nav from "./nav";
import Footer from "./footer";
import img1 from "./assets/images/picture102.jpg";
import img2 from "./assets/images/picture103.jpg";
import img3 from "./assets/images/picture104.jpg";
import img4 from "./assets/images/picture105.jpg";
import img5 from "./assets/images/picture106.jpg";
import img6 from "./assets/images/picture107.jpg";
import img7 from "./assets/images/picture108.jpg";
import img8 from "./assets/images/picture109.jpg";
import img9 from "./assets/images/picture110.jpg";
import img10 from "./assets/images/picture111.jpg";
import img11 from "./assets/images/picture211.jpg";
import img12 from "./assets/images/picture302.jpg";
import img13 from "./assets/images/picture304.jpg";
import img14 from "./assets/images/picture307.jpg";
import img15 from "./assets/images/picture412.jpg";
import img16 from "./assets/images/picture502.jpg";

// Import other local images here

const Gallery = () => {
	const itemData = [
		{
			img: img1,
			title: "Image of food",
			rows: 4,
			cols: 2,
		},
		{
			img: img2,
			title: "Image of table",
			rows: 2,
		},
		{
			img: img3,
			title: "Image of food",
			rows: 2,
		},
		{
			img: img4,
			title: "Image of food",
			cols: 2,
			rows: 2,
		},
		{
			img: img6,
			title: "Image of food",
			cols: 3,
			rows: 2,
		},
		{
			img: img5,
			title: "Image of food",
			cols: 1,
			rows: 2,
		},
		{
			img: img8,
			title: "Image of food",
			cols: 1,
			rows: 3,
		},
		{
			img: img7,
			title: "Image of food",
			cols: 3,
			rows: 3,
		},
		{
			img: img9,
			title: "Image of food",
			cols: 2,
			rows: 3,
		},
		{
			img: img10,
			title: "Image of food",
			cols: 2,
			rows: 3,
		},
		{
			img: img11,
			title: "Image of restaurant",
			cols: 1,
			rows: 3,
		},
		{
			img: img12,
			title: "Image of restaurant",
			cols: 3,
			rows: 3,
		},
		{
			img: img13,
			title: "Image of bar",
			cols: 3,
			rows: 2,
		},
		{
			img: img14,
			title: "Image of bar",
			cols: 1,
			rows: 4,
		},
		{
			img: img15,
			title: "Image of bar",
			cols: 1,
			rows: 2,
		},
		{
			img: img16,
			title: "Image of bar",
			cols: 2,
			rows: 2,
		},
	];

	return (
		<Box>
			<Nav />
			<Box display="flex" justifyContent="center" mt={4}>
				<Typography variant="h1">Gallery</Typography>
			</Box>
			<Box display="flex" justifyContent="center">
				<ImageList
					sx={{ width: 1000, height: 1000 }}
					variant="quilted"
					cols={4}
					rowHeight={121}
				>
					{itemData.map((item) => (
						<ImageListItem
							key={item.img}
							cols={item.cols || 1}
							rows={item.rows || 1}
						>
							<img src={item.img} alt={item.title} loading="lazy" />
						</ImageListItem>
					))}
				</ImageList>
			</Box>
			<Footer />
		</Box>
	);
};

export default Gallery;
