import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import takeAwayData from "../../assets/data/TakeAway/takeawaymenu";

const LebanesePlates = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box mb={4} sx={{ mx: isSmallScreen ? 3 : 8 }}>
        <Grid container mt={6}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              {takeAwayData[0].name}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: isSmallScreen ? "1em" : "1.3em" }}
            >
              {takeAwayData[0].description}
            </Typography>
          </Grid>
        </Grid>
        {takeAwayData[0].items.map((item, index) => (
          <Grid container mt={4}>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                ${item.price}
              </Typography>
            </Grid>
            <Grid item xs={9} px={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                {item.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box display="flex" justifyContent="center" mt={8}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h3">Banquet</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            $40 p.p.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              mb: 4,
              width: isSmallScreen ? "80%" : "50%",
              textAlign: "center",
            }}
          >
            Tabouli, Fatoush, Homous, Baba Ganouje, Beans, Omelette, Vine leaf,
            Cabbage Roll, Lady Finger, Lamb skewer, Chicken skewer and Rice,
            served with Lebanese bread.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LebanesePlates;
