import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import AlcoholicDrinksMenuData from "../../assets/data/alcoholic";

const Spirits = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box
        mb={4}
        sx={{
          mx: isSmallScreen ? 3 : 8,
          width: isSmallScreen ? "80vw" : "65vw",
        }}
      >
        <Grid container mt={6}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              {AlcoholicDrinksMenuData[1].name}
            </Typography>
          </Grid>
        </Grid>
        {AlcoholicDrinksMenuData[1].items.map((item, index) => (
          <Grid container mt={4}>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                ${item.price}
              </Typography>
            </Grid>
            <Grid item xs={9} px={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                {item.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid container mt={6}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              {AlcoholicDrinksMenuData[2].name}
            </Typography>
          </Grid>
        </Grid>
        {AlcoholicDrinksMenuData[2].items.map((item, index) => (
          <Grid container mt={4}>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                ${item.price}
              </Typography>
            </Grid>
            <Grid item xs={9} px={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
                {item.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default Spirits;
