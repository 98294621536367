import {
	Box,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import React from "react";
import Mezze from "./mezze";
import LebanesePlates from "./lebaneseplates";
import Seafood from "./seafood";
import LebaneseLightMeals from "./lebaneselightmeals";
import MeatDishes from "./meatdishes";
import ContinentalMeals from "./continentalmeals";

const Dinner = () => {
	const [alignment, setAlignment] = React.useState("left");
	const [showMezze, setShowMezze] = React.useState(true);
	const [showLebanesePlates, setShowLebanesePlates] = React.useState(false);
	const [showSeafood, setShowSeafood] = React.useState(false);
	const [showLebaneseLightMeals, setShowLebaneseLightMeals] =
		React.useState(false);
	const [showMeatDishes, setShowMeatDishes] = React.useState(false);
	const [showContinentalMeals, setShowContinentalMeals] = React.useState(false);

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string,
	) => {
		setAlignment(newAlignment);

		if (newAlignment === "mezze") {
			setShowMezze(true);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowLebaneseLightMeals(false);
			setShowMeatDishes(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "lebaneseplates") {
			setShowLebanesePlates(true);
			setShowMezze(false);
			setShowSeafood(false);
			setShowLebaneseLightMeals(false);
			setShowMeatDishes(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "seafood") {
			setShowSeafood(true);
			setShowMezze(false);
			setShowLebanesePlates(false);
			setShowLebaneseLightMeals(false);
			setShowMeatDishes(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "lebaneselightmeals") {
			setShowLebaneseLightMeals(true);
			setShowMezze(false);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowMeatDishes(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "meatdishes") {
			setShowMeatDishes(true);
			setShowMezze(false);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowLebaneseLightMeals(false);
			setShowContinentalMeals(false);
		} else if (newAlignment === "continentalmeals") {
			setShowContinentalMeals(true);
			setShowMezze(false);
			setShowLebanesePlates(false);
			setShowSeafood(false);
			setShowLebaneseLightMeals(false);
			setShowMeatDishes(false);
		}
	};

	const children = [
		<ToggleButton
			value="mezze"
			key="mezze"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Mezze
		</ToggleButton>,
		<ToggleButton
			value="lebaneseplates"
			key="lebaneseplates"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Lebanese Plates
		</ToggleButton>,
		<ToggleButton
			value="seafood"
			key="seafood"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Seafood
		</ToggleButton>,
		<ToggleButton
			value="lebaneselightmeals"
			key="lebaneselightmeals"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Lebanese Light Meals
		</ToggleButton>,
		<ToggleButton
			value="meatdishes"
			key="meatdishes"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Meat Dishes
		</ToggleButton>,
		<ToggleButton
			value="continentalmeals"
			key="continentalmeals"
			sx={{ color: "#9b3f3f", fontSize: "1em" }}
		>
			Continental Meals
		</ToggleButton>,
	];

	const control = {
		value: alignment,
		onChange: handleChange,
		exclusive: true,
	};
	return (
		<Box>
			<Box
				mt={5}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				textAlign="center"
			>
				<Typography variant="h3">Dinner</Typography>
				<Box>
					<Stack spacing={2} alignItems="center">
						<ToggleButtonGroup
							size="small"
							{...control}
							aria-label="Medium sizes"
							sx={{ flexWrap: "wrap", justifyContent: "center" }}
						>
							{children}
						</ToggleButtonGroup>
					</Stack>
				</Box>
			</Box>
			{showMezze && <Mezze />}
			{showLebanesePlates && <LebanesePlates />}
			{showSeafood && <Seafood />}
			{showLebaneseLightMeals && <LebaneseLightMeals />}
			{showMeatDishes && <MeatDishes />}
			{showContinentalMeals && <ContinentalMeals />}
		</Box>
	);
};

export default Dinner;
