import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import backgroundImage from "./assets/landingbackground.png";
import frontImg from "./assets/landingImg.jpg";
import tripAdviser from "./assets/TRIPADVISER.svg";
import Nav from "./nav";
import Footer from "./footer";

const Landing = () => {
	const isSmallScreen = useMediaQuery("(max-width: 900px)");
	const isMediumScreen = useMediaQuery("(max-width: 1200px)");
	return (
		<Box>
			<Nav />
			<Box
				sx={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					height: "100%",
					width: "100%",
				}}
			>
				<Grid container pt={10}>
					<Grid
						item
						xs={12}
						md={7}
						pt={17}
						sx={{
							pl: isSmallScreen ? 1 : 12,
							pt: isSmallScreen ? 8 : 17,
							pb: isSmallScreen || isMediumScreen ? 12 : 25,
						}}
						display="flex"
						flexDirection="column"
						justifyContent="center"
					>
						<Typography
							variant="h1"
							sx={{ fontSize: isSmallScreen ? "4.8em" : "5.2em" }}
						>
							Welcome To Rose's
						</Typography>
						<Box ml={2}>
							<Typography
								variant="h2"
								sx={{ fontSize: isSmallScreen ? "3em" : "3.2em" }}
							>
								Mediterranean Restaurant
							</Typography>
							<Divider
								sx={{
									backgroundColor: "#8a9470",
									height: "0.1px",
									width: "50%",
									my: 2,
								}}
							/>
							<Typography variant="h5">
								Open 6 days Dine In <br /> & Take Away
							</Typography>
						</Box>
					</Grid>
					{isSmallScreen ? null : (
						<Grid item xs={12} md={5} pt={10}>
							<div
								style={{
									background: "rgba(255, 255, 255, 0.05)",
									backdropFilter: "blur(3px)",
									WebkitBackdropFilter: "blur(5px)",
									borderRadius: "10px",
									border: "1px solid rgba(255, 255, 255, 0.3)",
									display: "flex",
									justifyContent: "center",
									paddingRight: "40px",
								}}
							>
								<img
									src={frontImg}
									alt="table and food"
									style={{
										width: isMediumScreen ? "400px" : "500px",
										height: "auto",
									}}
								/>
							</div>
						</Grid>
					)}
				</Grid>
			</Box>
			<Grid container my={10}>
				<Grid item xs={12} md={4} display="flex" justifyContent="center">
					<img src={tripAdviser} alt="tripadviser" width="250px" />
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Box width="80%" sx={{ mt: isSmallScreen ? "80px" : 0 }}>
						<Typography variant="body1">
							Rose's Lebanese Restaurant has been in the Cooma area for over 30
							years, upholding a tradition of fantastic food, combined with a
							modern, relaxed atmosphere. Rose's Lebanese Restaurant is a family
							owned and operated business.
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Footer />
		</Box>
	);
};

export default Landing;
