import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Roses from "./assets/Roses.jpeg";
import Footer from "./footer";
import Nav from "./nav";
import GiftVouchersImg from "./assets/gift-voucher-1024x527.jpg";
import fundRaisingImg1 from "./assets/images/fundraising1.jpeg";
import fundRaisingImg2 from "./assets/images/fundraising2.jpeg";

const FundRaising = () => {
	const isMediumScreen = useMediaQuery("(max-width:900px)");
	return (
		<Box>
			<Nav />
			<Grid container mt={2} mb={6}>
				{!isMediumScreen ? null : (
					<Grid
						item
						xs={12}
						md={4}
						my={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h1">Fund Raising</Typography>
					</Grid>
				)}
				<Grid item xs={12} md={8}>
					<Box
						sx={{
							backgroundImage: `url(${Roses})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							height: "80vh",
							display: "flex",
							alignItems: "center",
							padding: "1rem",
							justifyContent: "center",
						}}
					>
						<Box width="80%" height="80%" overflow="auto" textAlign="center">
							<Typography variant="body1">
								Rose's Restaurant can provide a variety of banquet options and
								entertainment for fundraising events. These combine ticket sales
								with a set menu, and can include the belly dancer as
								entertainment. We also have a PA system and space for a
								projector available.
							</Typography>
							<Typography variant="body1" sx={{ mt: 3 }}>
								Roses Restaurant is proud to be a sponsor of the Cooma branch of
								NSW Field & Game Association. We have sponsored club events for
								over ten years encouraging local and interstate competitors to
								not only enjoy a fantastic day of clay target competition but to
								ensure that local businesses are promoted during their visit to
								the Snowy Mountains Region. Our main sponsorship is currently
								set in conjunction with Snowy Camping World during the Easter
								four day weekend. Over 100 shooters travel long distances with
								their families and friends to enjoy what Cooma has to offer. We
								are pleased to help the Association with gift vouchers and
								prizes in order for clubs to continue supporting our local
								region.
							</Typography>
							<Box
								display="flex"
								flexWrap="wrap"
								justifyContent="center"
								alignItems="center"
							>
								<Box mr={6}>
									<img
										src={fundRaisingImg1}
										alt="fund rasing event"
										style={{
											width: isMediumScreen ? "200px" : "350px",
											height: "auto",
											marginTop: "50px",
											borderRadius: "15px",
										}}
									/>{" "}
								</Box>
								<img
									src={fundRaisingImg2}
									alt="fund rasing event"
									style={{
										width: isMediumScreen ? "150px" : "200px",
										height: "auto",
										marginTop: "50px",
										borderRadius: "15px",
									}}
								/>
							</Box>
							<Typography variant="body1" sx={{ mt: 3 }}>
								Gift Vouchers are available over the counter as a personal gift
								or to someone special, a thank you gift, birthdays and as raffle
								voucher for club events. Call into the Restaurant and ask one of
								our staff members to assist you with the presentation and
								validation of your voucher. We also offer no expiry date on the
								vouchers so that you have all the flexibility enjoy your dining
								experience when it best suits you.
							</Typography>
							<img
								src={GiftVouchersImg}
								alt="gift voucher"
								style={{
									width: isMediumScreen ? "200px" : "350px",
									height: "auto",
									marginTop: "50px",
								}}
							/>
						</Box>
					</Box>
				</Grid>
				{isMediumScreen ? null : (
					<Grid
						item
						pl={1}
						xs={12}
						md={4}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h1">Fund Raising</Typography>
					</Grid>
				)}
			</Grid>
			<Footer />
		</Box>
	);
};

export default FundRaising;
