import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            main: "#8a9470",
        },
    },
    typography: {
        fontFamily: "Payrus, sans-serif",
        h1: {
            color: "#9b3f3f",
            fontSize: "5.2em",
        },
        h2: {
            fontSize: "3.2em",
            color: "#3c5434", 
        },
        h3: {
            color: "#3c5434",
            fontSize: "2.5em",
        },
        h4: {
            color: "#8a9470",
        },
        h5: {
            color: "#8a9470",
        },
        h6: {
            color: "#8a9470",
        },
        body1: {
            color: "#3c5434",
            fontSize: "1.5rem",
        },
        body2: {
            color: "#8a9470",
            fontSize: "1rem",
        },
        button: {
            color: "#8a9470",
            fontSize: "1.7rem",
            textTransform: "none",
        }
    },
  });

  export default theme; 