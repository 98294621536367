import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import takeAwayMenuSides from "../../assets/data/TakeAway/Sides";

const Sides = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box mb={4} sx={{ mx: isSmallScreen ? 3 : 8 }}>
        <Grid container mt={6}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              {takeAwayMenuSides[0].name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              Small
            </Typography>
          </Grid>
          <Grid item xs={2} mr={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              Medium
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
              Large
            </Typography>
          </Grid>
        </Grid>
        {takeAwayMenuSides[0].items.map((item, index) => (
          <Grid container mt={4}>
            <Grid item xs={5}>
              <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                {item.small}
              </Typography>
            </Grid>
            <Grid item xs={2} mr={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                {item.medium}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                {item.large}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default Sides;
