const DinnerMenu = [
    {
        name: "Mezze",
        description: "",
        items: [
            {
                name: "Homemade Garlic bread or Herb bread",
                price: 8,
                description: "",
            },
              {
                name: "Soup of the Day",
                price: 12,
                description: ""
            },
            {
                name: "Labne",
                price: 9,
                description: "Thick homemade cow's milk yoghurt served with olive oil."
            },
            {
                name: "Homous",
                price: 9,
                description: "A homemade dip made of pureed chickpeas, tahini and lemon juice."
            },
            {
                name: "Baba Ganouje",
                price: 10,
                description: "A homemade dip made of pureed grilled eggplant, tahini and lemon juice."
            },
            {
                name: "Shankleesh",
                price: 14,
                description: "A crumbled aged Lebanese cheese served with tomato, red onion and olive oil."
            },
            {
                name: "Gibnee Baladiyee",
                price: 12,
                description: "Homemade Lebanese white cheese made with dairy milk, served with olives, pickled turnip and cucumber."
            },
            {
                name: "Falafel (5 pieces)",
                price: 15,
                description: "A mixture of lightly fried chickpeas, broad beans, onions, garlic and herbs served with tahini sauce."
            },
            {
                name: "Halloumi Pockets",
                price: 15,
                description: "Toasted Lebanese bread filled with halloumi cheese, tomato, red onion and olives."
            },
            {
                name: "Warak Ariesh",
                price: 9,
                description: "Marinated vine leaves wrapped with rice, parsley, tomato, onions and spices."
            },
            {
                name: "Garlic Prawns",
                price: 18,
                description: "Four specially prepared ocean king prawns presented on a bed of Rose's famous Lebanese rice and served with a garden salad."
            },
            {
                name: "Lady Fingers (6 pieces)",
                price: 16,
                description: "Filo Pastry filled with minced meat, pine nuts & herbs. Lightly fried and served with homemade Labne."
            },
            {
                name: "Mixed Mezza",
                price: 29,
                description: "Your choice of 5 mezza samples (serves 2-4) excludes garlic prawns."
            }
        ],
    },
    {
        name: "Lebanese Plates",
        description: "All meals served with Lebanese bread.",
        items: [
            {
                name: "Vegetarian Plate",    
                price: 33,
                description: "Homous, tabouli, baba ganouje, 3 falafels, beans, an omelette and a vine leaf."
            },
            {
                name: "Mixed Plate",
                price: 36,
                description: "Homous, tabouli, beans, cabbage roll, an omelette, rice, 1 chicken skewer and 1 lamb skewer."
            },
            {
                name: "Chicken Plate",
                price: 35,
                description: "Homous, tabouli, beans, cabbage roll, an omelette, rice and 2 chicken skewers."
            },
            {
                name: "Lamb Plate",
                price: 36,
                description: "Homous, tabouli, beans, cabbage roll, an omelette, rice and 2 lamb skewers."
            },
            {
                name: "Kafta Plate",
                price: 36,
                description: "Homous, tabouli, beans, cabbage roll, an omelette, rice and 2 kafta skewers."
            },
        ]
    },
    {
        name: "Seafood",
        description: "",
        items: [
            {
                name: "Samke Harrah",
                price: 38,
                description: "New Zealand ling fish fillets marinated in chilli, tahini, garlic and spices. Served with rice, fatoush salad and sprinkled with toasted almonds and pine nuts."
            },
            {
                name: "Iraydis",
                price: 38,
                description: "Eight ocean king prawns marinated in garlic and lemon juice served with fatoush salad, homous and Rose’s famous Lebanese rice."
            },
            {
                name: "Local Smoked Trout",
                price: 35,
                description: "Whole smoked local trout served with baba ganouje, rice and fatoush salad."
            },
        ]
    },
    {
        name: "Lebanese - Light Meals",
        description: "",
        items: [
            {
                name: "Doner Beef Meal",
                price: 19.50,
                description: "Marinated shaved beef served with lettuce, tomato, onion, tabouli, homous and tahini."
            },
            {
                name: "Falafel Meal (Vegetarian)",
                price: 19.50,
                description: "Freshly fried falafels served with lettuce, tomato, onion, tabouli, homous and tahini."
            },
            {
                name: "Chicken Meal",
                price: 19.50,
                description: "Fresh marinated chicken breast served with lettuce, tomato, onion, tabouli, homous and tahini."
            },
            {
                name: "Lamb Meal",
                price: 19.50,
                description: "Fresh marinated lamb served with lettuce, tomato, onion, tabouli, homous and tahini."
            },
            {
                name: "Mixed Meat Meal",
                price: 22.50,
                description: "Chicken, lamb and shaved beef served with lettuce, tomato, onion, tabouli, homous and tahini."
            }, 
        ]
    },
    {
        name: "Meat Dishes", 
        description: "",
        items: [
            {
                name: "Malfouf",
                price: 32,
                description: "A serving of cabbage rolls filled with a risotto style minced lamb, rice, mint, tomato, onions and spices. Served with fatoush salad, beans and homous.",
            },
            {
                name: "Shawarma",
                price: 32,
                description: "Shaved marinated beef topped with tahini, red onions and parsley. Served with fatoush salad, beans, homous and rice."
            },
            {
                name: "M’orniek",
                price: 32,
                description: "A serving of spiced beef sausages sprinkled with lemon juice. Served with fatoush salad, beans, homous and rice."
            },
            {
                name: "Kebbe Miklieyeh",
                price: 32,
                description: "Crushed wheat & minced lamb, stuffed with fine lamb, pine nuts, onions and herbs served with fatoush salad, beans, homous and labne."
            },
            {
                name: "Kafta dish",
                price: 32,
                description: "Spiced minced lamb, parsley and onions served on a skewer with rice, fatoush salad, beans and homous."
            },
            {
                name: "Lahum Mishwa",
                price: 32,
                description: "The finest lamb pieces marinated in garlic & spices. Topped with red onions and parsley. Served with fatoush salad, beans, homous and rice."
            },
        ]
    },
    {
        name: "Continental Meals",
        description: "",
        items: [
            {
                name: "Homemade spaghetti bolognaise",
                price: "26",
                description: "",
            },
            {
                name: "Schnitzel (Chicken or Veal)",
                price: "28",
                description: "Served with a garden salad & chips."
            },
            {
                name: "Local cut Rump Steak (500g)",
                price: "42",
                description: "Served with a garden salad, chips & your choice of sauce (gravy, dianne or pepper)."
            },
            {
                name: "Local cut sirloin Steak (300g)",
                price: "40",
                description: "Served with a garden salad, chips & your choice of sauce (gravy, dianne or pepper)."
            },
            {
                name: "Garlic Prawns",
                price: "38",
                description: "Eight ocean king prawns presented on a bed of rice and served with a garden salad."
            },
            {
                name: "Fisherman's Catch",
                price: "26",
                description: "Battered fish, prawns, scallop, calamari and crab meat, served with a wedge of lemon, garden salad & chips."
            },
        ]
    }
];

export default DinnerMenu;