import { Box } from "@mui/material";
import InfoPage from "./info";
import React from "react";

const Facilities = () => {
	return (
		<Box>
			<InfoPage
				title={"Facilities"}
				para1={
					"Rose’s Restaurant can cater for a wide range of needs. We provide for many dietary requirements, including options for gluten free, vegetarian, vegan and Halal meals. Restaurant facilities are also fully wheelchair accessible."
				}
				para2={
					"We are fully licensed with a fantastic range of wines, beers and spirits to complement any meal, including locally made products,. You can also bring your own wine if you prefer."
				}
				para3={
					"Another great facility is our children’s playroom. With games, toys and movies to keep the kids entertained, a fantastic time is guaranteed for every member of the family."
				}
				para4={
					"Rose’s is located right in the heart of the Cooma CBD, close to the park, shops, with plenty of free parking available. We have seating for up to 100, a PA system and space for a projector if needed."
				}
				img={""}
				altImg={""}
			/>
		</Box>
	);
};

export default Facilities;
