const Sides = [
      {
      name: "Sides",
      description: "",
      items: [
          {
              name: "Tabouli",
              small: "$6.00",
              medium: "$8.00",
              large: "$15.00",
          },
          {
              name: "Fatoush Salad",
              small: "$6.00",
              medium: "$8.00",
              large: "$13.00",
          },
          {
              name: "Homous",
              small: "$6.00",
              medium: "$8.00",
              large: "$15.00",
          },
          {
              name: "Baba Ganouje",
              small: "$6.50",
              medium: "$9.00",
              large: "$15.00",
          },
          {
              name: "Rice",
              small: "$5.50",
              medium: "$8.00",
              large: "$15.00",
          },
          {
              name: "Beans",
              small: "$7.00",
              medium: "$9.00",
              large: "$16.00",
          },
          {
              name: "Garlic",
              small: "$2.00",
              medium: "N/A",
              large: "$9.00",
          },
          {
              name: "Chips",
              small: "$5.00",
              medium: "N/A",
              large: "$9.00",
          },
        {
            name: "Skewers (All)",
            small: "$5.50",
            medium: "each",
            large: "",
        },
        {
            name: "Kebbe",
            small: "$4.50",
            medium: "each",
            large: "",
        },
        {
            name: "Cabbage Rolls",
            small: "$2.50",
            medium: "each",
            large: "",
        },
        {
            name: "Lady Fingers",
            small: "$2.50",
            medium: "each",
            large: "",
        },
        {
            name: "Omelettes",
            small: "$2.50",
            medium: "each",
            large: "",
        },
        {
            name: "Vine Leaves",
            small: "$2.00",
            medium: "each",
            large: "",
        },
        {
            name: "Leb Sausages",
            small: "$1.50",
            medium: "each",
            large: "",
        },
        {
            name: "Falafels",
            small: "$1.50",
            medium: "each",
            large: "",
        },
        {
            name: "Bread",
            small: "$0.50",
            medium: "each",
            large: "",
        },
      ]
  },
]


export default Sides;