import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DrinksMenuData from "../../assets/data/drinks";

const SoftDrinks = () => {
	const isSmallScreen = useMediaQuery("(max-width: 900px)");
	return (
		<Box>
			<Box
				mb={4}
				sx={{
					mx: isSmallScreen ? 3 : 8,
					width: isSmallScreen ? "80vw" : "65vw",
				}}
			>
				<Grid container mt={6}>
					<Grid item xs={10}>
						<Typography variant="h4" sx={{ color: "#9b3f3f" }}>
							{DrinksMenuData[0].name}
						</Typography>
					</Grid>
				</Grid>
				{DrinksMenuData[0].items.map((item, index) => (
					<Grid container mt={4}>
						<Grid item xs={10}>
							<Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
								{item.name}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
								${item.price}0
							</Typography>
						</Grid>
					</Grid>
				))}
			</Box>
		</Box>
	);
};

export default SoftDrinks;
