import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Nav from "./nav";
import background from "./assets/menubackground.png";
import React from "react";
import Lunch from "./menus/lunch/lunch";
import Footer from "./footer";
import Dinner from "./menus/dinner/dinner";
import Desserts from "./menus/desserts/desserts";
import Childrens from "./menus/childrens/childrens";
import Drinks from "./menus/drinks/drinks";
import TakeAway from "./menus/takeaway/TakeAway";

const Menus = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const [showLunch, setShowLunch] = React.useState(true);
  const [showDinner, setShowDinner] = React.useState(false);
  const [showDesserts, setShowDesserts] = React.useState(false);
  const [showChildrens, setShowChildrens] = React.useState(false);
  const [showDrinks, setShowDrinks] = React.useState(false);
  const [showTakeAway, setShowTakeAway] = React.useState(false);
  const [alignment, setAlignment] = React.useState("left");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);

    if (newAlignment === "left") {
      setShowLunch(true);
      setShowDinner(false);
      setShowDesserts(false);
      setShowChildrens(false);
      setShowDrinks(false);
      setShowTakeAway(false);
    } else if (newAlignment === "center") {
      setShowDinner(true);
      setShowLunch(false);
      setShowDesserts(false);
      setShowChildrens(false);
      setShowDrinks(false);
      setShowTakeAway(false);
    } else if (newAlignment === "right") {
      setShowDesserts(true);
      setShowLunch(false);
      setShowDinner(false);
      setShowChildrens(false);
      setShowDrinks(false);
      setShowTakeAway(false);
    } else if (newAlignment === "childrens") {
      setShowChildrens(true);
      setShowLunch(false);
      setShowDinner(false);
      setShowDesserts(false);
      setShowDrinks(false);
      setShowTakeAway(false);
    } else if (newAlignment === "justify") {
      setShowDrinks(true);
      setShowLunch(false);
      setShowDinner(false);
      setShowDesserts(false);
      setShowChildrens(false);
      setShowTakeAway(false);
    } else if (newAlignment === "takeaway") {
      setShowTakeAway(true);
      setShowLunch(false);
      setShowDinner(false);
      setShowDesserts(false);
      setShowChildrens(false);
      setShowDrinks(false);
    }
  };

  const children = [
    <ToggleButton
      value="left"
      key="left"
      sx={{ fontSize: isSmallScreen ? "1.2em" : "1.5em" }}
    >
      Lunch
    </ToggleButton>,
    <ToggleButton
      value="center"
      key="center"
      sx={{ fontSize: isSmallScreen ? "1.2em" : "1.5em" }}
    >
      Dinner
    </ToggleButton>,
    <ToggleButton
      value="childrens"
      key="childrens"
      sx={{ fontSize: isSmallScreen ? "1.2em" : "1.5em" }}
    >
      Childrens
    </ToggleButton>,
    <ToggleButton
      value="right"
      key="right"
      sx={{ fontSize: isSmallScreen ? "1.2em" : "1.5em" }}
    >
      Desserts
    </ToggleButton>,
    <ToggleButton
      value="justify"
      key="justify"
      sx={{ fontSize: isSmallScreen ? "1.2em" : "1.5em" }}
    >
      Drinks
    </ToggleButton>,
    <ToggleButton
      value="takeaway"
      key="takeaway"
      sx={{ fontSize: isSmallScreen ? "1.2em" : "1.5em" }}
    >
      Take Away
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };
  return (
    <Box>
      <Nav />
      <Box display="flex" justifyContent="center">
        <Typography
          variant="h1"
          sx={{ fontSize: isSmallScreen ? "4.5em" : "5.2em", mt: 4 }}
        >
          Menus
        </Typography>
      </Box>
      <Box>
        <Stack spacing={2} alignItems="center">
          <ToggleButtonGroup
            size="medium"
            {...control}
            aria-label="Medium sizes"
            sx={{ flexWrap: "wrap", justifyContent: "center" }}
          >
            {children}
          </ToggleButtonGroup>
        </Stack>
      </Box>
      <Box display="flex" justifyContent="center" mb={3}>
        <Box
          mt={2}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            width: isSmallScreen ? "97%" : "80%",
            border: "2px solid #9b3f3f",
            borderRadius: "5px",
          }}
        >
          <Box display="flex" justifyContent="center" minHeight="700px">
            {showLunch && <Lunch />}
            {showDinner && <Dinner />}
            {showDesserts && <Desserts />}
            {showChildrens && <Childrens />}
            {showDrinks && <Drinks />}
            {showTakeAway && <TakeAway />}
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Menus;
