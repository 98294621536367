const Desserts = [
    {
        name: "Kineffe",
        price: 10.5,
        description: "A firm custard semolina base, served warm, topped with cornflake crumbs and a rose water syrup.",
    },
    {
        name: "Ice cream - 3 scoops",
        price: 9.5,
        description: "Topped with your choice of chocolate, strawberry, vanilla or caramel flavouring."
    },
    {
        name: "Ice cream - 3 scoops with 2 Turkish delights",
        price: 12.5,
        description: "Topped with your choice of chocolate, strawberry, vanilla or caramel flavouring. Includes 2 Turkish delights."
    },
    {
        name: "Baclawa",
        price: 6.5,
        description: "A traditional pastry filled with cashew nuts. Baked, then served with a thick rose water syrup. Topped with pistachio nuts."
    },
    {
        name: "Baclawa with ice cream",
        price: 9.5,
        description: "A traditional pastry filled with cashew nuts. Baked, then served with a thick rose water syrup. Topped with pistachio nuts. Includes ice cream."
    },
    {
        name: "Flavoured Tiramisu",
        price: 10.5,
        description: "Sponge fingers, flavoured liqueurs and layers of cream, laid in a tiramisu style. See specials board for today’s flavour."
    },
    {
        name: "Affogato",
        price: 14.5,
        description: "Ice cream served with a full shot of Frangelico liqueur and a fragrant blast of local fresh ground coffee."
    },
    {
        name: "Drunken soldier",
        price: 10.5,
        description: "Layers of sponge fingers, cream and ANZAC biscuits soaked in 5 different liqueurs. Topped with a specially prepared butterscotch schnapps sauce."
    },
    {
        name: "Mixed sweet Platter",
        price: 7,
        description: "A selection of biscuits, mini Lebanese baclawa and Turkish delights. Price per person."
    },
    {
        name: "Turkish Delights",
        price: "4 (serve of 2 pieces)",
        description: ""
    },
    {
        name: "Turkish Delights",
        price: "3 (1 scoop)",
        description: ""
    },
    {
        name: "Kids Ice Cream - 1 scoop",
        price: 3,
        description: ""
    }
]

export default Desserts;