import { Box } from "@mui/material";
import InfoPage from "./info";
import React from "react";

const Functions = () => {
	return (
		<Box>
			<InfoPage
				title={"Functions"}
				para1={
					"Rose's Restaurant has seating for up to 100 as well as a children's playroom, fully licenced bar, wheelchair access and abundant parking, making it the perfect venue for any function, from weddings, christenings or birthdays to corporate meetings. We have a fantastic banquet menu, with flexible options to cater for any budget, or any sized group."
				}
				para2={
					"If you would like to Subscribe to our mailing list so you can be automatically notified of up and coming events please click here."
				}
				para3={
					"One of our most popular public functions are our belly dancing nights, which feature a fantastic combination of our traditional banquet food, authentic music, and entertainment from a talented belly dancer. These nights can also be booked for private functions with groups of 50 or more."
				}
				img={""}
				altImg={""}
				para4={""}
			/>
		</Box>
	);
};

export default Functions;
