import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import Landing from "./components/landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "./components/aboutUs";
import Contact from "./components/contact";
import Menus from "./components/menus";
import Gallery from "./components/gallery";
import Catering from "./components/catering";
import Functions from "./components/functions";
import GiftVouchers from "./components/giftVouchers";
import Facilities from "./components/facilities";
import FundRaising from "./components/fundRaising";
import React from "react";
import "./";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/menus" element={<Menus />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/catering" element={<Catering />} />
          <Route path="/functions" element={<Functions />} />
          <Route path="/giftvouchers" element={<GiftVouchers />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/fundraising" element={<FundRaising />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
