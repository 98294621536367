const Alcoholic = [
    {
        name: "Beer",
        items: [
            {
                name: "Heineken Zero",
                price: "6.00",
                description: ""
            },
            {
                name: "Cascade Light",
                price: "6.50",
                description: ""
            },
            {
                name: "XXXX Gold",
                price: "7.00",
                description: ""
            },
            {
                name: "Great Northern",
                price: "7.00",
                description: ""
            },
            {
                name: "VB",
                price: "7.00",
                description: ""
            },
            {
                name: "Carlton Draught",
                price: "8.00",
                description: ""
            },
            {
                name: "Carlton Dry",
                price: "8.00",
                description: ""
            },
            {
                name: "Toohey's Old",
                price: "8.50",
                description: ""
            },
            {
                name: "Crown Lager",
                price: "8.50",
                description: ""
            },
            {
                name: "Peroni Nastro (Italy)",
                price: "9.00",
                description: ""
            },
            {
                name: "Corona (Mexico)",
                price: "9.00",
                description: ""
            },
            {
                name: "Almaza (Lebanon)",
                price: "9.50",
                description: ""
            }
        ]
    },
    {
        name: "Spirits",
        items: [
            {
                name: "Scotch (Red Label)",
                price: "9.00",
                description: ""
            },
                {
                name: "Bourbon (Jim Beam)",
                price: "9.00",
                description: ""
            },
            {
                name: "Jack Daniels Whiskey",
                price: "9.00",
                description: ""
            },
            {
                name: "Rum (Bundaberg)",
                price: "9.00",
                description: ""
            },
            {
                name: "Southern Comfort",
                price: "9.00",
                description: ""
            },
            {
                name: "Vodka (Smirnoff)",
                price: "9.00",
                description: ""
            },
            {
                name: "Bacardi White Rum",
                price: "9.00",
                description: ""
            },
            {
                name: "Gin (Gordon's)",
                price: "9.00",
                description: ""
            },
            {
                name: "Canadian Club",
                price: "9.00",
                description: ""
            }
        ]
    },
    {
        name: "Top Shelf Spirits",
        items: [
            {
                name: "Johnnie Walker Black",
                price: "11.00",
                description: ""
            },
             {
                name: "Chivas Regal",
                price: "11.00",
                description: ""
            },
            {
                name: "Tequila",
                price: "11.00",
                description: ""
            },
            {
                name: "Arrak",
                price: "11.00",
                description: ""
            }
        ]
    },
    {
        name: "Liquors/ Aperitifs",
        items: [
            {
                name: "Sweet Sherry",
                price: "9.00",
                description: ""
            },
            {
                name: "Brandy",
                price: "9.00",
                description: ""
            },
            {
                name: "Midori",
                price: "9.00",
                description: ""
            },
            {
                name: "Bailey's",
                price: "9.00",
                description: ""
            },
            {
                name: "Tia Maria",
                price: "9.00",
                description: ""
            },
            {
                name: "Tawny Port",
                price: "9.00",
                description: ""
            },
            {
                name: "Kahlua",
                price: "9.00",
                description: ""
            },
            {
                name: "Malibu",
                price: "9.00",
                description: ""
            }
        ]
    },
    {
        name: "Top Shelf Liquors",
        items: [
            {
                name: "Jägermeister",
                price: "9.50",
                description: ""
            },
            {
                name: "Sambuca",
                price: "9.50",
                description: ""
            },
            {
                name: "Frangelico",
                price: "9.50",
                description: ""
            },
            {
                name: "Cointreau",
                price: "9.50",
                description: ""
            },
            {
                name: "Drambuie",
                price: "9.50",
                description: ""
            },
            {
                name: "Grand Mariner",
                price: "9.50",
                description: ""
            },
            {
                name: "Grandfather's Port",
                price: "12.00",
                description: ""
            },
            {
                name: "Cocktail Mix",
                price: "15.00",
                description: ""
            }
        ]
    },
    {
        name: "Cider",
        items: [
            {
                name: "Strongbow Original Apple Cider",
                price: "8.00",
                description: ""
            },
            {
                name: "Strongbow Sweet Apple Cider",
                price: "8.00",
                description: ""
            },
            {
                name: "Somersby Pear Cider",
                price: "8.00",
                description: ""
            }
        ]
    },
    {
        name: "Sparkling",
        items: [
            {
                name: "Wine Spritzer",
                price: "8.00",
                description: ""
            },
            {
                name: "Brown Brothers (200ml)",
                price: "12.00",
                description: "Moscato Milawa, VIC"
            },
            {
                name: "Jacobs Creek",
                price: "22.00   $12.00 (200ml)",
                description: "Chardonnay Pinot Noir Barossa Valley, SA"
            },
            {
                name: "Moet & Chandon",
                price: "22.00",
                description: "Brut Champagne, France"
            }
        ]
    }
];

export default Alcoholic;