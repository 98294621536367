const LunchMenu = [
  {
    name: "Kebabs",
    description:
      "Presented open or wrapped. Kebabs are served with lettuce, tomato, onion, tabouli, homous & tahini. Rolled in Lebanese bread",
    items: [
      {
        name: "Doner (Shaved beef)",
        price: 15.50,
        description: "",
      },
      {
        name: "Falafel (vegetarian)",
        price: 15.50,
        description: "",
      },
      {
        name: "Chicken",
        price: 15.50,
        description: "",
      },
      {
        name: "Lamb",
        price: 16.50,
        description: "",
      },
      {
        name: "Mixed Meats",
        price: 16.5,
        description: "(All three meats or your choice)",
      },
    ],
  },
  {
    name: "Light Meals",
    description: "",
    items: [
      {
        name: "Dipping plate",
        price: 28.50,
        description: "Your selection of any five:",
        options: [
          {
            name: "Labne",
            description: "homemade yoghurt dip",
          },
          {
            name: "Homous",
            description: "chickpea dip",
          },
          {
            name: "Baba Ghanouje",
            description: "smoked eggplant dip",
          },
          {
            name: "Shankleeh",
            description: "herb-infused cow milk cheese",
          },
          {
            name: "Gibnee Baladiyee",
            description: "white cow's milk cheese in brine",
          },
          {
            name: "Falafel",
            description: "chickpea and broad bean mix, lightly fried",
          },
          {
            name: "Halloumi pockets",
            description: "with tomato, onion & olives",
          },
          {
            name: "Warak Ariesh",
            description: "vine leaves",
          },
          {
            name: "Lady Fingers",
            description:
              "filo pastry filled with minced meat, pine nuts & herbs",
          },
          {
            name: "Garlic or Her bread",
            description: "",
          },
        ],
      },
      {
        name: "Rizz & Lahum",
        price: 18.50,
        description:
          "Your choice of doner beef, lamb, chicken or falafel, served on a bed or rice. Topped with grilled tomato and your choice of sauce (tahini, garlic, chilli, sweet chilli, BBQ or mayo).",
      },
      {
        name: "Fatoush Salad",
        price: 18.50,
        description:
          "Your choice of doner beef, lamb, chicken or falafel. Topped with grilled tomato and your choice of sauce (tahini, garlic, chilli, sweet chilli, BBQ or mayo).",
      },
      {
        name: "Toasted Lebanese Pockets",
        price: 14.50,
        description:
          "Toasted Lebanese bread filled with halloumi cheese, tomato, red onions and olives. Served with a garden salad.",
      },
    ],
  },
  {
    name: "Lebanese Plates",
    description: "All meals served with Lebanese bread",
    items: [
    {
      name: "Vegetarian Plate",
      price: 20.0,
      description: "Homous, tabouli, baba ganouje, 2 falafels, an omelette and beans.",
    },
    {
      name: "Mixed Plate",
      price: 22.0,
      description: "Homous, tabouli, beans and rice with your choice of 1 skewer (chicken, lamb or kafta).",
    },
    {
      name: "Malfouf",
      price: 22.0,
      description:
        "A serving of cabbage rolls filled with a risotto-style minced lamb, rice, mint, tomato, onions and spices, served with fatoush salad, beans and homous.",
    },
    {
      name: "Kebbe Miklieyeh",
      price: 22.0,
      description:
        "Crushed wheat & minced lamb, stuffed with fine lamb, pine nuts, onions and herbs. Served with Fatoush salad, beans, homous and labne.",
    },
    {
      name: "Shawarma",
      price: 22.0,
      description:
        "Shaved slices of marinated beef, topped with tahini, red onions and parsley. Served with Fatoush salad, beans, homous and rice.",
    },
    {
      name: "M'orniek",
      price: 22.0,
      description:
        "Serving of spiced beef sausages sprinkled with lemon juice. Served with fatoush salad, beans, homous and rice.",
    },
    {
      name: "Lahum Mishwa",
      price: 22.0,
      description:
        "Finest lamb pieces marinated in spices, garlic, onion and olive oil. Served with fatoush salad, beans, homous and rice.",
    },
    {
      name: "Banquet",
      price: 45.0,
      description:
        "Homous, baba ganouje, tabouli, beans, vine leaf, cabbage roll, lady finger, lamb skewer, chicken skewer, an omelette and rice.",
    },
  ],
},
{
  name: "Seafood",
  description: "",
  items: [
    {
      name: "Samke Harrah",
      price: 28,
      description: "New Zealand ling fish fillets marinated in chilli, tahini, garlic and spices. Served with rice, fatoush salad then sprinkled with toasted almonds and pine nuts."
    },
        {
      name: "Garlic Prawns",
      price: 28,
      description:
        "Six specially prepared ocean king prawns presented on a bed of Rose's Lebanese rice. Served with a garden salad.",
    },
    {
      name: "Fisherman's Catch",
      price: 26,
      description:
        "Battered fish, prawns, scallops, calamari and crab meat served with a wedge of lemon, salad and chips.",
    },
    {
      name: "Whole Smoked Trout",
      price: 35,
      description:
        "Snowy Mountain's Trout served with rice, baba ganouje and fatoush salad.",
    },
  ]
},
{
  name: "Continental Meals",
  description: "",
  items: [
    {
      name: "Schnitzel",
      price: 28,
      description: "(Chicken or Veal) served with salad & chips."
    },
        {
      name: "Local cut Rump steak (500g)",
      price: 42,
      description:
        "Served with salad, chips & your choice of sauce (gravy, pepper or dianne).",
    },
    {
      name: "Local cut Sirloin steak (300g)",
      price: 40,
      description:
        "Served with a garden salad, chips & your choice of sauce (gravy, pepper or dianne).",
    },
    {
      name: "Homemade Spaghetti Bolognaise",
      price: 26,
      description: "",
    },
    {
      name: "Soup of the day",
      price: 12,
      description: "",
    },
    {
      name: "Plate of chips",
      price: 10,
      description: "",
    },
    {
      name: "Home-made Garlic or Herb bread",
      price: 8,
      description: "",
    },
  ],
},
];


export default LunchMenu;