import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Nav from "./nav";
import Roses from "./assets/Roses.jpeg";
import Footer from "./footer";

const AboutUs = () => {
	const isMediumScreen = useMediaQuery("(max-width:900px)");
	return (
		<Box>
			<Nav />
			<Grid container mt={2} mb={10}>
				{!isMediumScreen ? null : (
					<Grid
						item
						xs={12}
						md={4}
						my={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h1">About Us</Typography>
					</Grid>
				)}
				<Grid item xs={12} md={8}>
					<Box
						sx={{
							backgroundImage: `url(${Roses})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							height: "90vh",
							display: "flex",
							alignItems: "center",
							padding: "1rem",
							justifyContent: "center",
						}}
					>
						<Box
							width="80%"
							height="80%"
							display="flex"
							justifyContent="center"
							overflow="auto"
						>
							<Typography variant="body1">
								Rose's Lebanese Restaurant has been in the Cooma area for over
								13 years, upholding a tradition of fantastic food, combined with
								a modern, relaxed atmosphere. Rose's Lebanese Restaurant is a
								family owned and operated business. <br /> <br /> All efforts
								are made to source locally grown organic produce. Our growers do
								not use chemicals or preservatives, and by sourcing produce from
								within the local area the freshest food comes virtually straight
								from the farm to your table. <br /> <br /> We support many local
								community organisations, as well as larger charitable groups
								through either sponsorship or donations. <br /> <br /> Some of
								the orgainsations we support are: <br /> St Patrick's Parish
								School Fundraising Golf Day <br /> Westmead Children's Hospital{" "}
								<br />
								Canteen Cancer Foundation <br /> MS Society <br /> Variety
								Children's Charity <br /> Cooma Hospital Fundraisers <br />{" "}
								Cooma Cats AFL <br /> <br /> Rose's is an award winning
								Restaurant, winning Best European Restaurant of South East NSW
								at the 2009 ACT and Southern NSW Restaurant and Catering Awards,
								as well as a finalist placing in Best Middle Eastern Restaurant
								at the 2008 ACT and Southern NSW Restaurant and Catering Awards.
							</Typography>
						</Box>
					</Box>
				</Grid>
				{isMediumScreen ? null : (
					<Grid
						item
						xs={12}
						md={4}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h1">About Us</Typography>
					</Grid>
				)}
			</Grid>
			<Footer />
		</Box>
	);
};

export default AboutUs;
