import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DinnerMenuData from "../../assets/data/dinner";

const LebanesePlates = () => {
	const isSmallScreen = useMediaQuery("(max-width: 900px)");
	return (
		<Box>
			<Box mb={4} sx={{ mx: isSmallScreen ? 3 : 8 }}>
				<Grid container mt={6}>
					<Grid item xs={10}>
						<Typography variant="h4" sx={{ color: "#9b3f3f" }}>
							{DinnerMenuData[1].name}
						</Typography>
						<Typography
							variant="body1"
							sx={{ fontSize: isSmallScreen ? "1em" : "1.3em" }}
						>
							{DinnerMenuData[1].description}
						</Typography>
					</Grid>
				</Grid>
				{DinnerMenuData[1].items.map((item, index) => (
					<Grid container mt={4}>
						<Grid item xs={10}>
							<Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
								{item.name}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
								${item.price}
							</Typography>
						</Grid>
						<Grid item xs={9} px={2}>
							<Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
								{item.description}
							</Typography>
						</Grid>
					</Grid>
				))}
			</Box>
			<Box display="flex" justifyContent="center" mt={8}>
				<Box display="flex" alignItems="center" flexDirection="column">
					<Typography variant="h3">Banquet</Typography>
					<Typography variant="body1" sx={{ mt: 2 }}>
						$45.00 p.p.
					</Typography>
					<Typography
						variant="body2"
						sx={{
							mt: 2,
							width: isSmallScreen ? "80%" : "50%",
							textAlign: "center",
						}}
					>
						Tabouli, fatoush salad, homous, baba ganouje, beans, vine leaf,
						cabbage roll, lady finger, an omelette, lamb skewer, chicken skewer
						& rice served with Lebanese bread.
					</Typography>
					<Typography
						variant="body2"
						sx={{
							mt: 2,
							mb: 6,
							width: isSmallScreen ? "80%" : "50%",
							textAlign: "center",
						}}
					>
						Please note that the selection of foods presented for the Banquet
						may vary due to the availability of seasonal produce
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default LebanesePlates;
