const Childrens = [
    {
        name: "Mini mixed plate",
        price: 14.5,
        description: "A skewer of chicken, lamb or kafta served with rice, homous and tabouli.",
    },
    {
        name: "Kids skewer dish",
        price: 14.5,
        description: "A skewer of chicken, lamb or kafta served with rice, chips & salad."
    },
    {
        name: "Panko crumbed flathead fish, chips & salad",
        price: 12.5,
        description: ""
    },
    {
        name: "Chicken or Veal schnitzel",
        price: 13.5,
        description: "Served with chips and salad."
    },
    {
        name: "Homemade spaghetti bolognaise",
        price: 12,
        description: ""
    },
    {
        name: "Sausages, chips and salad",
        price: 12,
        description: ""
    }
];

export default Childrens;