import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DinnerMenuData from "../../assets/data/dinner";

const LebaneseLightMeals = () => {
	const isSmallScreen = useMediaQuery("(max-width: 900px)");
	return (
		<Box>
			<Box mb={4} sx={{ mx: isSmallScreen ? 3 : 8 }}>
				<Grid container mt={6}>
					<Grid item xs={10}>
						<Typography variant="h4" sx={{ color: "#9b3f3f" }}>
							{DinnerMenuData[3].name}
						</Typography>
						<Typography
							variant="body1"
							sx={{ fontSize: isSmallScreen ? "1em" : "1.3em" }}
						>
							{DinnerMenuData[3].description}
						</Typography>
					</Grid>
				</Grid>
				{DinnerMenuData[3].items.map((item, index) => (
					<Grid container mt={4}>
						<Grid item xs={10}>
							<Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
								{item.name}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
								${item.price}0
							</Typography>
						</Grid>
						<Grid item xs={9} px={2}>
							<Typography sx={{ fontSize: isSmallScreen ? "0.8em" : "1.2em" }}>
								{item.description}
							</Typography>
						</Grid>
					</Grid>
				))}
			</Box>
		</Box>
	);
};

export default LebaneseLightMeals;
