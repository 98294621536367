import {
	Box,
	Button,
	Divider,
	Menu,
	MenuItem,
	useMediaQuery,
} from "@mui/material";
import img from "./assets/rosese logo.jpeg";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import React from "react";
import { Link } from "react-router-dom";
import SmallNav from "./smallNav";

const Nav = () => {
	const [more, setMore] = React.useState<null | HTMLElement>(null);
	const smallNav = useMediaQuery("(max-width:975px)");
	const openMore = Boolean(more);
	const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMore(event.currentTarget);
	};
	const handleMoreClose = () => {
		setMore(null);
	};

	return (
		<Box>
			{!smallNav ? (
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Button href="/">
						<img
							src={img}
							alt="logo"
							style={{ width: "230px", height: "auto", paddingLeft: "30px" }}
						/>
					</Button>
					<Box display="flex">
						<Button href="/" variant="text" sx={{ pl: 2 }}>
							Home
						</Button>
						<Button href="/about" variant="text" sx={{ pl: 2 }}>
							About Us
						</Button>
						<Button href="/menus" variant="text" sx={{ pl: 2 }}>
							Menus
						</Button>
						<Button href="/contact" variant="text" sx={{ pl: 2 }}>
							Contact Us
						</Button>
						<Button
							variant="contained"
							sx={{ mx: 2, width: "130px" }}
							id="basic-button"
							aria-controls={openMore ? "more-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={openMore ? "true" : undefined}
							onClick={handleMoreClick}
							endIcon={<ArrowDropDownCircleIcon />}
						>
							More
						</Button>
						<Menu
							id="more-menu"
							anchorEl={more}
							open={openMore}
							onClose={handleMoreClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							<MenuItem
								component={Link}
								to="/catering"
								onClick={handleMoreClose}
							>
								Catering
							</MenuItem>
							<MenuItem
								component={Link}
								to="/functions"
								onClick={handleMoreClose}
							>
								Functions
							</MenuItem>
							<Divider />
							<MenuItem
								component={Link}
								to="/fundraising"
								onClick={handleMoreClose}
							>
								Fund Raising
							</MenuItem>
							<MenuItem
								component={Link}
								to="/giftvouchers"
								onClick={handleMoreClose}
							>
								Gift Vouchers
							</MenuItem>
							<MenuItem
								component={Link}
								to="/gallery"
								onClick={handleMoreClose}
							>
								Gallery
							</MenuItem>
							<MenuItem
								component={Link}
								to="/facilities"
								onClick={handleMoreClose}
							>
								Facilities
							</MenuItem>
						</Menu>
					</Box>
				</Box>
			) : (
				<SmallNav />
			)}
		</Box>
	);
};

export default Nav;
