import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import WineMenuData from "../../assets/data/wine";

const RedWine = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box>
      <Box
        mb={4}
        sx={{
          mx: isSmallScreen ? 3 : 8,
          width: isSmallScreen ? "80vw" : "65vw",
        }}
      >
        <Grid container mt={6}>
          <Grid item xs={8}>
            <Typography variant="h4" sx={{ color: "#9b3f3f" }}>
              {WineMenuData[0].name}
            </Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: isSmallScreen ? "1em" : "1.5em",
                textDecoration: "underline",
              }}
            >
              Glass
            </Typography>
            <Typography
              sx={{
                fontSize: isSmallScreen ? "1em" : "1.5em",
                textDecoration: "underline",
              }}
            >
              Bottle
            </Typography>
          </Grid>
        </Grid>
        {WineMenuData[0].items.map((item, index) => (
          <Grid container mt={4}>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: isSmallScreen ? "1.1em" : "1.5em" }}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="space-between">
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                ${item.glass}
              </Typography>
              <Typography sx={{ fontSize: isSmallScreen ? "1em" : "1.5em" }}>
                ${item.bottle}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Box mt={4}>
          <Typography
            sx={{
              fontSize: isSmallScreen ? "1em" : "1.5em",
              textAlign: "center",
            }}
          >
            Corkage BYO $6.00 per bottle of wine
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RedWine;
