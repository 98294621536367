import { Box } from "@mui/material";
import InfoPage from "./info";
import GiftVouchersImg from "./assets/gift-voucher-1024x527.jpg";
import React from "react";

const GiftVouchers = () => {
	return (
		<Box>
			<InfoPage
				title={"Gift Vouchers"}
				para1={
					"Gift Vouchers are available over the counter as a personal gift or to someone special, a thank you gift, birthdays and as raffle voucher for club events."
				}
				para2={
					"Call Roses Restaurant on 02 6452 4512 to organize a gift voucher. We can send it via post or you can come into the shop and pick it up."
				}
				para3={
					"Call into the Restaurant and ask one of our staff members to assist you with the presentation and validation of your voucher. We also offer no expiry date on the vouchers so that you have all the flexibility enjoy your dining experience when it best suits you."
				}
				img={GiftVouchersImg}
				altImg={"Image of gift vouchers"}
				para4={""}
			/>
		</Box>
	);
};

export default GiftVouchers;
